import React, { Component } from 'react';
import { Card, CardBody, CardGroup, Col, Container, Form, Row } from 'reactstrap';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import { getAPIUrl } from '../../utils/urlUtils';



axios.defaults.withCredentials = true





class Login extends Component {


  responseGoogle = (response) => {

    console.log(response);

    if(response.credential){

      axios.get(`${getAPIUrl()}/login`,
        {
          headers: {Authorization: 'Bearer ' + response.credential}
        }).then(data => {
          this.props.history.push('/')
        })
    }
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In with your google account</p>

                      <Row>
                        <Col xs="6">
                          {/*<GoogleLogin
                            clientId="849426612749-565bs5sl0ak08ejkbsqtid1d2ahfe6bt.apps.googleusercontent.com"
                            buttonText="Login"
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                            cookiePolicy={'single_host_origin'}
                          />,*/}
                          <GoogleOAuthProvider clientId={"849426612749-565bs5sl0ak08ejkbsqtid1d2ahfe6bt.apps.googleusercontent.com"}>
                            <GoogleLogin onSuccess={this.responseGoogle} onFailure={this.responseGoogle} useOneTap/>
                          </GoogleOAuthProvider>

                        </Col>

                      </Row>
                    </Form>
                  </CardBody>
                </Card>

              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
