import dotenv from 'dotenv'
dotenv.config()

export const getAPIUrl = () => {
    const {REACT_APP_API_HOST, REACT_APP_API_PORT} = process.env
    return REACT_APP_API_HOST+((REACT_APP_API_PORT==="80") ? '' : `:${REACT_APP_API_PORT}`)
}

export const getProxyImgUrl = (originalUrl) => {
  const {REACT_APP_API_HOST} = process.env
    return `${REACT_APP_API_HOST}/proxy-img?img=${encodeURIComponent(originalUrl)}`;
}
